import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
import { default as React } from 'react';
import Button from './Button';

const ContactFormButton = ({ children, className, ...props }) => {
    return (
        <Button onClick={() => navigate('/kontakt')}>
            Zum Kontakformular{' '}
            <FontAwesomeIcon size="1x" icon={faArrowRight} className="ml-2" />
        </Button>
    );
};

export default ContactFormButton;
