import classNames from 'classnames';
import React from 'react';

const Button = ({ children, className, ...props }) => {
    return (
        <button
            className={classNames(
                'text-sm bg-transparent hover:bg-blue text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded',
                className
            )}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
