import classNames from 'classnames';
import styled from 'styled-components';

const Section = styled.section.attrs(
	({ noTopBorder, noTopSpacing, bgGreyLighter, noBottomSpacing }) => ({
		className: classNames('section py-12 border-top-1 border-grey-light', {
			'border-t-0': noTopBorder,
			'bg-custom-blue-lighter': bgGreyLighter,
			'pt-0': noTopSpacing,
			'pb-0': noBottomSpacing,
		}),
	}),
)``;

export default Section;
