import trimEnd from 'lodash/trimEnd';
import * as data from './nav-data';

export const isActive = (url, location) => {
    if (!location || !url) return false;

    return trimEnd(location.pathname, '/') === trimEnd(url, '/');
};

export const hasActiveChild = (item, location) => {
    if (!item.children || !item.children.length) return false;

    const foundActiveChild = item.children.find((child) =>
        isActive(child.link, location)
    );

    if (foundActiveChild) return true;

    return false;
};

export const stripTrailingSlash = (str) => {
    return str.endsWith('/') ? str.slice(0, -1) : str;
};

export default data;
