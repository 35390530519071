module.exports = [
	{
		label: 'Startseite',
		link: '/',
	},
	{
		label: 'Praxis',
		link: '/praxis',
		children: [
			{
				label: 'Dr. med. Michael H. Krokowski',
				link: '/praxis/dr-med-michael-h-krokowski',
			},
			{
				label: 'Praxis-Team',
				link: '/praxis/praxis-team',
			},
			// {
			// 	label: 'Freie Stellen',
			// 	link: '/praxis/freie-stellen',
			// },
			{
				label: 'Mitgliedschaften',
				link: '/praxis/mitgliedschaften',
			},
			{
				label: 'Anfahrt',
				link: '/praxis/anfahrt',
			},
		],
	},
	// {
	//     label: 'Ärzte',
	//     crumbLink: '/aerzte',
	//     children: [
	//         {
	//             label: 'Dr. med. Michael H. Krokowski',
	//             link: '/aerzte/dr-med-michael-h-krokowski',
	//         },
	//         {
	//             label: 'Dr. med. Stefan Füller',
	//             link: '/aerzte/dr-med-stefan-fueller',
	//         },
	//     ],
	// },
	// {
	//     label: 'Behandlungsspektrum',
	//     link: '/behandlungsspektrum',
	//     full: true,
	//     children: [
	//         {
	//             label: 'Med. Dermatologie',
	//             link: '/behandlungsspektrum/medizinische-dermatologie',
	//             children: [
	//                 {
	//                     label: 'Akne und Aknenarben',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/akne-und-aknenarben',
	//                 },
	//                 {
	//                     label:
	//                         'Ambulante Operationen und dermatologische Tumortherapie',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/ambulante-operationen-und-dermatologische-tumorchirurgie',
	//                 },
	//                 {
	//                     label: 'Haarausfall bei Mann und Frau',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/haarausfall-bei-mann-und-frau',
	//                 },
	//                 {
	//                     label: 'Hautkrebsvorsorge',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/hautkrebsvorsorge',
	//                 },
	//                 {
	//                     label: 'Klassische dermatologische Erkrankungen',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/klassische-dermatologische-erkankungen',
	//                 },
	//                 {
	//                     label: 'Nagelpilzerkrankungen',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/nagelpilzerkrankungen',
	//                 },
	//                 {
	//                     label: 'Rosacea und Couperose',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/rosacea-und-couperose',
	//                 },
	//                 {
	//                     label: 'Sonnenlichtschäden und Hautkrebsvorstufen',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/sonnenlichtschaeden-der-haut-und-hautkrebsvorstufen',
	//                 },
	//                 {
	//                     label: 'Venenleiden',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/venenleiden',
	//                 },
	//                 {
	//                     label: 'Warzenbehandlung',
	//                     link:
	//                         '/behandlungsspektrum/medizinische-dermatologie/warzenbehandlung',
	//                 },
	//             ],
	//         },
	//         {
	//             label: 'Ästh. Dermatologie',
	//             link: '/behandlungsspektrum/aesthetische-dermatologie',
	//             children: [
	//                 {
	//                     label: 'Behandlung mimischer Fältchen mit Botox',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/warzenbehandlung',
	//                 },
	//                 {
	//                     label: 'Behandlung statischer Fältchen mit Fillern',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/behandlung-statischer-faeltchen-mit-fillern',
	//                 },
	//                 {
	//                     label: 'Fadenlifting',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/fadenlifting',
	//                 },
	//                 {
	//                     label: 'Medizinisches Peeling',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/medizinisches-peeling',
	//                 },
	//                 {
	//                     label: 'Mesotherapie',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/mesotherapie',
	//                 },
	//                 {
	//                     label: 'Micro-Needling',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/micro-needling',
	//                 },
	//                 {
	//                     label: 'Narbenbehandlung',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/narbenbehandlung',
	//                 },
	//                 {
	//                     label:
	//                         'Pelleve schonende Hautstraffung mittels Radiofrequenz',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/pelleve-schonende-hautstraffung-mittels-radiofrequenz',
	//                 },
	//                 {
	//                     label: 'Radiofrequenz-Needling',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/radiofrequenz-needling',
	//                 },
	//                 {
	//                     label: 'Vampir Lift mit PRP-Therapie',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/vampir-lift-prp-therapie',
	//                 },
	//                 {
	//                     label: 'Verschönerung der Augen',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/verschoenerung-der-augen',
	//                 },
	//                 {
	//                     label: 'Volumenfiller',
	//                     link:
	//                         '/behandlungsspektrum/aesthetische-dermatologie/volumenfiller',
	//                 },
	//             ],
	//         },
	//         {
	//             label: 'Ästh. Lasertherapie',
	//             link: '/behandlungsspektrum/aesthetische-lasertherapie',
	//         },
	//     ],
	// },
	{
		label: 'Medizinische Dermatologie',
		link: '/medizinische-dermatologie',
		columns: true,
		// full: true,
		children: [
			{
				label: 'Klassische dermatologische Erkrankungen',
				link:
					'/medizinische-dermatologie/klassische-dermatologische-erkankungen',
			},
			{
				label:
					'Ambulante Operationen und dermatologische Tumortherapie',
				link:
					'/medizinische-dermatologie/ambulante-operationen-und-dermatologische-tumorchirurgie',
			},
			{
				label: 'Hautkrebsvorsorge',
				link: '/medizinische-dermatologie/hautkrebsvorsorge',
			},
			{
				label: 'Warzenbehandlung',
				link: '/medizinische-dermatologie/warzenbehandlung',
			},
			{
				label: 'Akne und Aknenarben',
				link: '/medizinische-dermatologie/akne-und-aknenarben',
			},
			{
				label: 'Nagelpilzerkrankungen',
				link: '/medizinische-dermatologie/nagelpilzerkrankungen',
			},
			{
				label: 'Rosacea und Couperose',
				link: '/medizinische-dermatologie/rosacea-und-couperose',
			},
			{
				label: 'Haarausfall bei Mann und Frau',
				link:
					'/medizinische-dermatologie/haarausfall-bei-mann-und-frau',
			},

			{
				label: 'Sonnenlichtschäden und Hautkrebsvorstufen',
				link:
					'/medizinische-dermatologie/sonnenlichtschaeden-der-haut-und-hautkrebsvorstufen',
			},
			{
				label: 'Venenleiden',
				link: '/medizinische-dermatologie/venenleiden',
			},
		],
	},
	{
		label: 'Ästhetische Dermatologie',
		link: '/aesthetische-dermatologie',
		columns: true,
		// full: true,
		children: [
			{
				label: 'Mimische Fältchen / Botox',
				link:
					'/aesthetische-dermatologie/behandlung-mimischer-faeltchen-mit-botox',
			},
			{
				label: 'Mesotherapie',
				link: '/aesthetische-dermatologie/mesotherapie',
			},
			{
				label: 'Statische Fältchen / Filler',
				link:
					'/aesthetische-dermatologie/behandlung-statischer-faeltchen-mit-fillern',
			},
			{
				label: 'Körper Contouring mit Injektionslipolyse',
				link:
					'/aesthetische-dermatologie/koerper-contouring-mit-injektionslipolyse',
			},
			{
				label: 'Augenlidverbesserung / Schlupflider / Tränensäcke',
				link:
					'/aesthetische-dermatologie/augenlidverbesserung-schlupflider-traenensaecke',
			},
			{
				label: 'Volumenfiller',
				link: '/aesthetische-dermatologie/volumenfiller',
			},
			{
				label: 'Narbenbehandlung',
				link: '/aesthetische-dermatologie/narbenbehandlung',
			},
			{
				label: 'Fadenlifting',
				link: '/aesthetische-dermatologie/fadenlifting',
			},
			{
				label: 'Vampir Lift / PRP',
				link: '/aesthetische-dermatologie/vampir-lift-prp-therapie',
			},
			{
				label: 'Micro-Needling',
				link: '/aesthetische-dermatologie/micro-needling',
			},
			{
				label: 'Radiofrequenz-Needling',
				link: '/aesthetische-dermatologie/radiofrequenz-needling',
			},
			{
				label: 'Pelleve / Radiofrequenztherapie',
				link:
					'/aesthetische-dermatologie/pelleve-schonende-hautstraffung-mittels-radiofrequenz',
			},
			{
				label: 'Medizinisches Peeling',
				link: '/aesthetische-dermatologie/medizinisches-peeling',
			},
		],
	},
	// {
	//     label: 'Plastische Chirugie',
	//     link: '/plastische-chirurgie',
	// },
	{
		label: 'Kontakt',
		link: '/kontakt',
		children: [
			{
				label: 'Kontaktformular',
				link: '/kontakt',
			},
			{
				label: 'Anfahrt',
				link: '/kontakt/anfahrt',
			},
			{
				label: 'Datenschutz',
				link: '/kontakt/datenschutz',
			},
			{
				label: 'Impressum',
				link: '/kontakt/impressum',
			},
		],
	},
];
