import { Link } from 'gatsby';
import React from 'react';
import NavigationData from '../utils/navigation';

const findNavItem = (item, root) => {
    for (let i = 0; i < root.length; i++) {
        if (root[i].link && root[i].link === item.pathname) return root[i];

        if (root[i].children) {
            return findNavItem(item, root[i].children);
        }
    }

    return null;
};

const Breadcrumb = ({ data }) => {
    if (!data) return null;

    const Item = ({ item, index }) => {
        const navitem = findNavItem(item, NavigationData);
        const isLast = index === data.crumbs.length - 1;
        const label = navitem ? navitem.label : item.crumbLabel;

        return (
            <React.Fragment>
                {isLast ? (
                    <span>{label}</span>
                ) : (
                    <li>
                        <Link
                            to={item.pathname}
                            className="text-darken-light no-underline hover:text-darken"
                        >
                            {label}
                        </Link>
                    </li>
                )}

                {!isLast ? (
                    <li className="mx-3 text-grey cursor-default">
                        <span>/</span>
                    </li>
                ) : null}
            </React.Fragment>
        );
    };

    return (
        <ul className="flex flex-no-wrap list-reset m-0">
            {data.crumbs.map((item, i) => {
                return (
                    <Item key={`${item.label}__${i}`} index={i} item={item} />
                );
            })}
        </ul>
    );
};

export default Breadcrumb;
