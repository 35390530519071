import {
	faClock,
	faInfoCircle,
	faMapMarkerAlt,
	faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import ContactFormButton from './ContactFormButton';
import OpeningTimeTable from './OpeningTimeTable';
import RouteLink from './RouteLink';
import Slogan from './slogan';
import Column from '../components/Column';
import Grid from '../components/Grid';

const Footer = () => (
	<footer>
		<div className="bg-custom-blue-lighter py-12 border-grey-light border-t text-grey-darkest">
			<div className="container">
				<Grid>
					<Column md="1/3">
						<div className="flex items-center">
							<FontAwesomeIcon
								size="2x"
								icon={faClock}
								className="mr-3"
							/>
							<h4 className="mb-0">Öffnungszeiten</h4>
						</div>
						<OpeningTimeTable className="mt-4" />
						<strong>und nach Vereinbarung.</strong>
					</Column>
					<Column md="1/3" className="mt-8 md:mt-0">
						<div className="flex items-center">
							<FontAwesomeIcon
								size="2x"
								icon={faPhone}
								className="mr-3"
							/>
							<h4 className="mb-0">Termin vereinbaren</h4>
						</div>

						<div
							className="flex border border-grey-dark bg-grey-light text-grey-darkest text-sm font-bold px-4 py-3 mt-6 rounded"
							role="alert"
						>
							<FontAwesomeIcon
								size="2x"
								icon={faInfoCircle}
								className="mr-3 text-lg"
							/>
							<p className="mb-0">
								Sprechzeiten nur nach Terminvereinbarung
							</p>
						</div>

						<p className="mt-6">
							<table>
								<tbody>
									<tr>
										<th>Montag - Freitag</th>
										<td className="text-center">
											08:30 Uhr - 11:30 Uhr
										</td>
									</tr>
								</tbody>
							</table>
							Tel.:{' '}
							<a
								className="text-black no-underline"
								href="tel:+495617036955"
							>
								(0561) - 7 03 69 55
							</a>
						</p>
						<p>und über das Kontaktformular:</p>
						<p>
							<ContactFormButton />
						</p>
					</Column>
					<Column md="1/3" className="mt-8 md:mt-0">
						<div className="flex items-center">
							<FontAwesomeIcon
								size="2x"
								icon={faMapMarkerAlt}
								className="mr-3"
							/>
							<h4 className="mb-0">Anfahrt & Kontakt</h4>
						</div>
						<p className="mt-6">
							<strong>Praxis der Dermatologie & Aesthetic</strong>
							<br />
							Kölnische Straße 5<br />
							34117 Kassel
						</p>
						<p className="mt-2">
							<RouteLink />
						</p>
					</Column>
				</Grid>
			</div>
		</div>
		<div className="bg-blue py-4">
			<div className="container text-white">
				<Link
					to="/"
					className="no-underline text-base text-white hover:text-grey-light"
				>
					<Slogan />
				</Link>
			</div>
		</div>
	</footer>
);

export default Footer;
