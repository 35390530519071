import React from 'react';

const Slogan = ({ size, className }) => (
	<div className={`${size ? 'text-size' : ''} ${className}`}>
		<div className="font-light">
			Praxis <span className="text-xs">der</span> Dermatologie{' '}
			<span className="text-xs">&</span> Aesthetic
		</div>
		<div className="font-bold mt-1 md:mt-2">Dr. med. Michael Krokowski</div>
	</div>
);

export default Slogan;
