import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Breadcrumb from './Breadcrumb';
import Column from './Column';
import ContactSidebar from './ContactSidebar';
import Footer from './footer';
import Grid from './Grid';
import Header from './header';
import NavBar from './NavBar';
import PageTitle from './PageTitle';
import Section from './Section';
import SEO from './seo';
import SidebarColumn from './SidebarColumn';
import Slider from './Slider';
import './tailwind.css';
import TopBar from './TopBar';

const Layout = ({
	children,
	location,
	title,
	pageTitle,
	hideBreadcrumbs,
	hideSidebar,
	pageContext,
	sliderImageIndex,
	isHomepage,
	noSlider,
	hidePageTitle,
	sidebarProps,
	preContent = null,
}) => {
	const Top = () => {
		return (
			<>
				{!hideBreadcrumbs && (
					<Breadcrumb
						location={location}
						data={pageContext && pageContext.breadcrumb}
					/>
				)}
				{!hidePageTitle && (
					<PageTitle
						isHomepage={isHomepage}
						title={pageTitle || title}
					/>
				)}
			</>
		);
	};

	return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={(data) => (
				<>
					<SEO title={title} />
					<TopBar />
					<Header
						isHome={location && location.pathname === '/'}
						siteTitle={data.site.siteMetadata.title}
					/>
					<NavBar location={location} />
					{!noSlider && <Slider imageIndex={sliderImageIndex} />}

					{preContent && (
						<section className="section pt-12">
							<div className="container">{preContent}</div>
						</section>
					)}
					<main>
						{hideSidebar ? (
							<>
								<Section noTopBorder noBottomSpacing>
									<div className="container">
										<Top />
									</div>
								</Section>
								{children}
							</>
						) : (
							<section className="section pt-12">
								<div className="container">
									<div className="pb-12">
										<Grid noNegativMargin>
											<Column md="2/3">
												<Top />
												{children}
											</Column>
											<SidebarColumn>
												<ContactSidebar
													{...sidebarProps}
												/>
											</SidebarColumn>
										</Grid>
									</div>
								</div>
							</section>
						)}
					</main>

					<Footer />
					<CookieConsent buttonText="Ich stimme zu">
						Diese Website benutzt Cookies. Cookies ermöglichen
						einige Funktionen, die es uns erleichtern zu verstehen,
						wie unsere Webseite benutzt wird. Dazu gehört auch die
						Analyse von Webseitenbesuchen. Mehr dazu findest du in
						den Datenschutzbestimmungen.
					</CookieConsent>
				</>
			)}
		/>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
