import classNames from 'classnames';
import React from 'react';
import { OpeningTimes } from '../utils/constants';

const OpeningTimeTable = ({ className, children }) => {
	return (
		<table className={classNames(className)}>
			<tbody>
				{Object.keys(OpeningTimes).map((dayNumber) => {
					return OpeningTimes[dayNumber].times.map((timeRange, i) => (
						<tr key={`${dayNumber}_${i}`}>
							<th>
								{i === 0 ? OpeningTimes[dayNumber].label : ''}
							</th>
							<td className="text-center">
								{timeRange[0]} Uhr - {timeRange[1]} Uhr
							</td>
						</tr>
					));
				})}
			</tbody>
		</table>
	);
};

export default OpeningTimeTable;
