import classNames from 'classnames';
import React from 'react';

const Column = ({ children, md, className, ...props }) => {
    return (
        <div
            className={classNames(
                'w-full px-3',
                {
                    [`md:w-${md}`]: !!md,
                },
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};

export default Column;
