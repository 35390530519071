import { graphql, StaticQuery } from 'gatsby';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import Img from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';
import Slogan from './slogan';
import Button from './Button';
import { Link } from 'gatsby';
import { useMedia } from 'react-use';
import './slider.css';

const Slider = ({ imageIndex }) => (
	<StaticQuery
		query={graphql`
			query {
				sliderImages: allFile(
					filter: { relativePath: { glob: "slider/*" } }
				) {
					edges {
						node {
							name
							childImageSharp {
								fluid(
									maxWidth: 1920
									maxHeight: 440
									quality: 100
								) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}

				sliderImagesMobile: allFile(
					filter: { relativePath: { glob: "slider_mobile/*" } }
				) {
					edges {
						node {
							name
							childImageSharp {
								mobileFluid: fluid(
									maxWidth: 616
									maxHeight: 440
									quality: 100
								) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => {
			return <SliderCarousel data={data}></SliderCarousel>;
		}}
	/>
);

const SliderCarousel = ({ data }) => {
	const [sortedImages, setSortedImages] = useState([]);
	const isMobile = useMedia('(max-width: 767px)');
	const carouselRef = useRef(null);

	useEffect(() => {
		if (data.sliderImages && data.sliderImages.edges) {
			let edges = isMobile
				? [...data.sliderImagesMobile.edges]
				: [...data.sliderImages.edges];

			edges.sort((a, b) => {
				if (a.node.name === 'slider_0') {
					return -1;
				}

				if (a.node.name === 'slider_covid') {
					if (b.node.name === 'slider_0') {
						return 1;
					} else {
						return -1;
					}
				} else if (b.node.name === 'slider_covid') {
					if (a.node.name === 'slider_0') {
						return -1;
					} else {
						return 1;
					}
				}

				return a.node.name.localeCompare(b.node.name);
			});

			setSortedImages(edges);
		}
	}, [data, isMobile]);

	useEffect(() => {
		if (carouselRef.current && sortedImages) {
			carouselRef.current.resetAutoPlay();
		}
	}, [carouselRef, sortedImages]);

	if (!sortedImages) return null;

	return (
		<Carousel
			ref={carouselRef}
			// autoPlay
			// interval={4000}
			showStatus={false}
			swipeable
			infiniteLoop
			showThumbs={false}
		>
			{sortedImages.map((image, i) => {
				return (
					<div
						className="relative"
						key={`slider_slide_${image.node.name}`}
					>
						{image.node.name === 'slider_0' && (
							<div
								key={`label_start_${image.node.name}`}
								className="flex absolute text-blue container flex-col justify-center items-start z-50 pin-t pin-l pin-r pin-b"
							>
								<div className="justify-start rounded items-start text-base md:text-xl text-left p-2 md:p-4 bg-white">
									<div className="mb-4">
										Willkommen bei der
									</div>
									<Slogan className="text-md md:text-lg" />
								</div>
								<div className="mt-4">
									<Link
										to="/praxis"
										className="text-sm md:text-md no-underline bg-blue text-white hover:bg-white hover:text-blue font-semibold py-2 px-4 border border-blue rounded"
									>
										Mehr erfahren
										<FontAwesomeIcon
											icon={faLongArrowAltRight}
											className="ml-3"
										/>
									</Link>
								</div>
							</div>
						)}

						{image.node.name === 'slider_covid' && (
							<div
								key={`label_covid_${image.node.name}`}
								className="flex absolute text-blue container flex-col justify-center items-start z-50 pin-t pin-l pin-r pin-b"
							>
								<div className="justify-start rounded items-start text-left p-4 bg-white">
									<div className="mb-4 text-md md:text-xl">
										Aktuelle Informationen zur Corona-Krise
									</div>
									<div className="text-sm md:text-md">
										Wir sind auch in Coronazeiten für Sie
										da. Bitte beachten Sie die gesetzlich
										vorgeschriebenen Sicherheitsmaßnahmen.
									</div>
								</div>
								<div className="mt-4">
									<Link
										to="/aktuelles/informationen-zu-corona"
										className="text-sm md:text-md no-underline bg-blue text-white hover:bg-white hover:text-blue font-semibold py-2 px-4 border border-blue rounded"
									>
										Mehr erfahren
										<FontAwesomeIcon
											icon={faLongArrowAltRight}
											className="ml-3"
										/>
									</Link>
								</div>
							</div>
						)}
						<div>
							<Img
								key={`image_${image.node.name}`}
								style={{
									minHeight: '220px',
								}}
								imgStyle={
									{
										// objectPosition: 'center right',
									}
								}
								fluid={
									isMobile
										? image.node.childImageSharp.mobileFluid
										: image.node.childImageSharp.fluid
								}
							/>
						</div>
					</div>
				);
			})}
		</Carousel>
	);
};

export default Slider;
