import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function SEO({ description, lang, meta, title }) {
	const keywords = [`krokowski`, `dermatologie`, 'praxis', 'ästhetic'];

	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				const metaDescription =
					description || data.site.siteMetadata.description;
				return (
					<Helmet
						htmlAttributes={{
							lang,
						}}
						title={title}
						titleTemplate={`%s | ${data.site.siteMetadata.title}`}
						meta={[
							{
								name: `description`,
								content: metaDescription,
							},
							{
								property: `og:title`,
								content: title,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								name: `twitter:card`,
								content: `summary`,
							},
							{
								name: `twitter:creator`,
								content: data.site.siteMetadata.author,
							},
							{
								name: `twitter:title`,
								content: title,
							},
							{
								name: `twitter:description`,
								content: metaDescription,
							},
						]
							.concat(
								keywords.length > 0
									? {
											name: `keywords`,
											content: keywords.join(`, `),
									  }
									: [],
							)
							.concat(meta)}
					>
						<script type="application/ld+json">
							{JSON.stringify({
								'@context': 'https://schema.org',
								'@graph': [
									{
										'@type': 'Organization',
										'@id':
											'https://www.krokowski-dermatologie.de/#organization',
										name:
											'Praxis der Dermatologie & Aesthetic',
										url:
											'https://www.krokowski-dermatologie.de/',
										sameAs: [],
										logo: {
											'@type': 'ImageObject',
											'@id':
												'https://www.krokowski-dermatologie.de/#logo',
											inLanguage: 'de-DE',
											url:
												'https://www.krokowski-dermatologie.de/logo.png',
											width: 260,
											height: 260,
											caption:
												'Praxis der Dermatologie & Aesthetic',
										},
										image: {
											'@id':
												'https://www.krokowski-dermatologie.de/#logo',
										},
									},
									{
										'@type': 'WebSite',
										'@id':
											'https://www.krokowski-dermatologie.de/#website',
										url:
											'https://www.krokowski-dermatologie.de/',
										name:
											'Praxis der Dermatologie & Aesthetic',
										description:
											'Haut- und Laserzentrum Kassel',
										publisher: {
											'@id':
												'https://www.krokowski-dermatologie.de/#organization',
										},
										potentialAction: [
											{
												'@type': 'SearchAction',
												target:
													'https://www.krokowski-dermatologie.de/?s={search_term_string}',
												'query-input':
													'required name=search_term_string',
											},
										],
										inLanguage: 'de-DE',
									},
									{
										'@type': 'WebPage',
										'@id':
											'https://www.krokowski-dermatologie.de/#webpage',
										url:
											'https://www.krokowski-dermatologie.de/',
										name:
											'Startseite - Praxis der Dermatologie & Aesthetic',
										isPartOf: {
											'@id':
												'https://www.krokowski-dermatologie.de/#website',
										},
										about: {
											'@id':
												'https://www.krokowski-dermatologie.de/#organization',
										},
										datePublished:
											'2020-07-10T10:23:59+00:00',
										dateModified:
											'2020-07-10T12:02:43+00:00',
										description: metaDescription,
										inLanguage: 'de-DE',
										potentialAction: [
											{
												'@type': 'ReadAction',
												target: [
													'https://www.krokowski-dermatologie.de/',
												],
											},
										],
									},
								],
							})}
						</script>
					</Helmet>
				);
			}}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				author
			}
		}
	}
`;
