import React from 'react';
import ContactFormButton from './ContactFormButton';
import OpeningTimeTable from './OpeningTimeTable';

const ContactSidebar = ({ children, questions }) => {
	return (
		<>
			<h2 className="text-blue font-normal mb-4 border-b border-grey-light pb-3">
				Kontakt
			</h2>
			{questions && <div className="mb-2">{questions}</div>}
			<p>
				<strong>Praxis der Dermatologie & Aesthetic</strong>
				<br />
				Kölnische Straße 5<br />
				34117 Kassel
				<br />
				<br />
				Tel.: (0561) - 7 03 69 55
			</p>
			<ContactFormButton />
			<div className="hidden md:block">
				<h2 className="text-blue font-normal mb-4 border-b border-grey-light pb-3 mt-12">
					Öffnungszeiten
				</h2>
				<OpeningTimeTable className="mt-0" />
			</div>
		</>
	);
};

export default ContactSidebar;
