import React from 'react';
import Column from './Column';

const SidebarColumn = ({ children }) => {
    return (
        <Column
            md="1/3"
            className="pt-2 border-blue-lightest pl-3 md:border-l mt-12 md:mt-0"
        >
            {children}
        </Column>
    );
};

export default SidebarColumn;
