import classNames from 'classnames';
import React from 'react';

const Grid = ({ children, className, noNegativMargin, noNegativeX }) => {
	return (
		<div
			className={classNames('flex flex-wrap', className, {
				'-my-3': !noNegativMargin,
				'-mx-3': !noNegativeX,
			})}
		>
			{children}
		</div>
	);
};

export default Grid;
