import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getISODay from 'date-fns/getISODay';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import add from 'date-fns/add';
import isWithinInterval from 'date-fns/isWithinInterval';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Slogan from './slogan';
import {
	OpeningTimes,
	VacationTimes,
	getVacationData,
} from '../utils/constants';

const HeaderLink = () => (
	<Link
		to="/"
		className="no-underline text-base text-blue hover:text-blue-dark"
	>
		<Slogan />
	</Link>
);

const Header = ({ siteTitle, isHome }) => {
	const currentDay = getISODay(new Date());

	const [vacationData, setVacationData] = useState(null);
	const [phoneOpen, setPhoneOpen] = useState(true);
	const [vacationEndDate, setVacationEndDate] = useState(null);
	const [isOpen, setIsOpen] = useState(
		OpeningTimes[currentDay] ? true : false,
	);

	useEffect(() => {
		// Check vacation
		const vacationData = getVacationData();

		if (vacationData) {
			setVacationData(vacationData.vacationData);
			setVacationEndDate(vacationData.vacationEndDate);

			if (vacationData.isOpen !== null) {
				setIsOpen(vacationData.isOpen);
			}

			if (vacationData.phoneOpen !== null) {
				setPhoneOpen(vacationData.phoneOpen);
			}
		}
	}, []);

	const getOpenTime = () => {
		if (!isOpen || vacationData !== null) return null;

		const todayOpen = OpeningTimes[currentDay];

		if (!todayOpen) return null;

		return todayOpen.times.map((timeRange, i) => (
			<div key={i}>{timeRange[0] + ' - ' + timeRange[1] + ' Uhr'}</div>
		));
	};

	return (
		<header>
			<div className="container">
				<div className="flex justify-between py-8 items-center">
					<div>
						{isHome ? (
							<h1 className="mb-0">
								<HeaderLink />
							</h1>
						) : (
							<h2 className="mb-0">
								<HeaderLink />
							</h2>
						)}
					</div>
					<div className="items-center md:flex hidden pl-4">
						<div className="flex items-center mr-10">
							<FontAwesomeIcon
								icon={faClock}
								className="mr-3 text-grey-darker"
								size="2x"
							/>
							<div>
								<div className="text-sm text-grey-darker">
									Heute geöffnet:
								</div>
								<div className="text-sm font-bold">
									{isOpen
										? getOpenTime()
										: vacationData
										? `Die Praxis ist bis  ${vacationEndDate} geschlossen.`
										: '-'}
								</div>
							</div>
						</div>
						{phoneOpen && (
							<div className="items-center flex">
								<FontAwesomeIcon
									icon={faPhone}
									className="mr-3 text-grey-darker"
									size="2x"
								/>
								<div>
									<div className="text-sm text-grey-darker">
										Rufen Sie uns an:
									</div>
									<div className="text-sm font-bold">
										<a
											className="text-black no-underline"
											href="tel:+495617036955"
										>
											(0561) - 7 03 69 55
										</a>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
