import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';

const TopBar = () => (
	<div className="bg-custom-blue-lighter border-b border-grey-light">
		<div className="container">
			<div className="flex justify-between text-sm py-2 text-grey-darkest flex-col md:flex-row">
				<div className="flex items-center">
					<span className="mr-10">
						<FontAwesomeIcon
							icon={faMapMarkerAlt}
							className="mr-2"
						/>
						Kölnische Straße 5, 34117 Kassel
					</span>
					<span className="hidden md:block">
						<FontAwesomeIcon icon={faPhone} className="mr-2" />
						(0561) - 7 03 69 55
					</span>
				</div>
				<div className="mt-2 md:mt-0">
					<Link
						to="/kontakt/impressum"
						className="text-grey-darkest no-underline hover:underline"
					>
						Impressum
					</Link>
					<span className="mx-2">|</span>
					<Link
						to="/kontakt/datenschutz"
						className="text-grey-darkest no-underline hover:underline"
					>
						Datenschutz
					</Link>
					<span className="mx-2">|</span>
					<Link
						to="/kontakt"
						className="text-grey-darkest no-underline hover:underline"
					>
						Kontakt
					</Link>
					<span className="mx-2">|</span>
					<Link
						to="/kontakt/anfahrt"
						className="text-grey-darkest no-underline hover:underline"
					>
						Anfahrt
					</Link>
				</div>
			</div>
		</div>
	</div>
);

export default TopBar;
