import {
    faAngleDown,
    faAngleRight,
    faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { hasActiveChild, isActive } from '../utils/navigation';
import MobileNavLink from './MobileNavLink';

const MobileNavItem = ({ item, location }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
    const hasDropdown = item.children && item.children.length > 0;
    const active =
        isActive(item.link, location) || hasActiveChild(item, location);

    const clickedItem = e => {
        if (hasDropdown) {
            e.preventDefault();
            toggleDropdown();
        }
    };

    const Inner = ({ active }) => {
        return (
            <>
                <div
                    onClick={clickedItem}
                    className={classNames(
                        'no-underline flex justify-between p-4 border-b border-grey',
                        {
                            'font-bold': active,
                            'text-lighten': !active,
                            'text-white': active,
                        }
                    )}
                >
                    {item.label}

                    <FontAwesomeIcon
                        className="ml-2"
                        icon={
                            hasDropdown
                                ? isDropdownOpen
                                    ? faAngleUp
                                    : faAngleDown
                                : faAngleRight
                        }
                        size="lg"
                    />
                </div>

                {hasDropdown && isDropdownOpen ? (
                    <>
                        {item.link && (
                            <MobileNavLink
                                location={location}
                                label="Übersicht"
                                data={item}
                            />
                        )}
                        {item.children.map((child, i) => (
                            <MobileNavLink
                                location={location}
                                key={`${item.label}__${i}`}
                                data={child}
                            />
                        ))}
                    </>
                ) : null}
            </>
        );
    };

    if (item.link && !hasDropdown) {
        return (
            <Link to={item.link} className="no-underline">
                <Inner />
            </Link>
        );
    }

    return <Inner active={active} />;
};

export default MobileNavItem;
