import classNames from 'classnames';
import React from 'react';

const PageTitle = ({ title, subtitle, isHomepage }) => {
    const Tag = `h${isHomepage ? 2 : 1}`;

    return (
        <>
            <Tag
                className={classNames('text-blue font-normal', {
                    'mb-8': !subtitle,
                    'mb-3': !!subtitle,
                })}
            >
                {title}
            </Tag>
            {subtitle && (
                <h2 className="text-blue font-light mb-8 text-xl">
                    {subtitle}
                </h2>
            )}
        </>
    );
};

export default PageTitle;
