import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const RouteLink = () => {
	return (
		<a
			className="text-sm bg-transparent hover:bg-blue text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded no-underline"
			href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
				'Praxis der Dermatologie & Aesthetic',
			)}+${encodeURIComponent('Kölnische Straße 5')}+34117+Kassel`}
			target="_blank"
			rel="noopener noreferrer"
		>
			Route zur Praxis berechnen
			<FontAwesomeIcon icon={faExternalLinkAlt} className="ml-3" />
		</a>
	);
};

export default RouteLink;
