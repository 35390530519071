import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import chunk from 'lodash/chunk';
import React, { useState } from 'react';
import styled from 'styled-components';
import NavigationData, { hasActiveChild, isActive } from '../utils/navigation';
import MobileNavItem from './MobileNavItem';

const StyledLink = styled(Link).attrs({
    className: 'text-white no-underline flex items-center relative',
})``;

const ItemInner = styled.span.attrs({
    className: 'flex items-center px-4',
})``;

const NavbarDropdown = styled.div.attrs({
    className: 'absolute pin-l bg-white hidden shadow-lg',
})`
    top: 100%;
    min-width: 200px;
    z-index: 9999;
`;

const NavbarColumnsMenu = styled.div.attrs({
    className: 'absolute pin-l bg-white hidden shadow-lg',
})`
    top: 100%;
    min-width: 200px;
    z-index: 9999;
`;

const NavbarColumnsItem = styled.div.attrs({
    className: '',
})``;

const NavbarFullwidthLeft = styled.div.attrs({
    className: 'absolute pin-b bg-white hidden shadow-lg',
})`
    left: -5000px;
    width: 5000px;
    height: 100%;
    display: block;
    z-index: 9999;

    &:after {
        content: '';
        position: absolute;
        display: block;
        right: -3px;
        bottom: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: #fff;
    }
`;

const NavbarFullwidthRight = styled.div.attrs({
    className: 'absolute pin-b bg-white hidden shadow-lg',
})`
    right: -5000px;
    width: 5000px;
    height: 100%;
    display: block;
    z-index: 9999;

    &:after {
        content: '';
        position: absolute;
        display: block;
        left: -3px;
        bottom: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: #fff;
    }
`;

const NavbarFullwidthContent = styled.div.attrs({
    className: '',
})``;

const NavbarFullwidthMenu = styled.div.attrs({
    className: 'absolute pin-l pin-r bg-white hidden shadow-lg p-3',
})`
    top: 100%;
    min-width: 300px;
    z-index: 9999;
`;

const NavbarFullwidthSubmenu = styled.div.attrs({
    className: '',
})`
    float: left;

    &:not(:last-child) {
        margin-right: 20px;
    }
`;

const NavbarFullwidthSubmenuHead = styled(Link).attrs({
    className:
        'text-blue-dark font-bold no-underline border-b block hover:text-blue-darker hover:underline py-2',
})``;

const NavbarFullwidthSubmenuLink = styled(Link).attrs({
    className:
        'text-grey-darkest no-underline py-1 block hover:underline hover:text-black',
})``;

const NavbarItem = styled.div.attrs((props) => ({
    className: classNames(
        'navbar-item h-12 inline-flex items-stretch text-white no-underline hover:bg-blue-dark',
        {
            relative: !props.isFullwidth,
        }
    ),
}))`
    ${(props) =>
        props.active &&
        `
        ${ItemInner}{
            :before{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 3px;
                background: #fff;
            }
        }
    `}

    cursor: ${(props) => (props.hasDropdown ? 'default' : 'inherit')};

    &:not(:last-child) {
        &:after {
            content: '';
            width: 1px;
            z-index: 2;
            background-color: rgba(255, 255, 255, 0.4);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            margin: 0.6rem 0;
        }
    }

    &:hover {
        ${NavbarDropdown} {
            display: block;
        }

        ${NavbarColumnsMenu} {
            display: flex;
        }

        ${NavbarFullwidthMenu}{
            display: block;
        }

        ${NavbarFullwidthLeft}{
            display: block;
        }

        ${NavbarFullwidthRight}{
            display: block;
        }
    }
`;

const NavbarDropdownItem = styled(Link).attrs((props) => ({
    className: `text-black no-underline flex justify-between items-center py-2 px-4 hover:bg-grey-lighter`,
}))`
    min-width: 270px;
`;

const NavBar = ({ location }) => {
    const [isMobileNavigationOpen, setMobileNavigationOpen] = useState(false);

    const toggleMobileNavigation = (e) => {
        e.preventDefault();

        setMobileNavigationOpen(!isMobileNavigationOpen);
    };

    return (
        <div className="bg-blue">
            <div className="container">
                <div className="flex items-stretch lg:hidden justify-end">
                    <button
                        className="text-white px-4 flex items-center -mr-4 h-12"
                        onClick={toggleMobileNavigation}
                    >
                        <span className="mr-3">Navigation</span>
                        <FontAwesomeIcon icon={faBars} size="lg" />
                    </button>
                </div>
                {isMobileNavigationOpen && (
                    <div className="block lg:hidden">
                        {NavigationData.map((item) => (
                            <MobileNavItem
                                location={location}
                                item={item}
                                key={`mobile__${item.label}`}
                            />
                        ))}
                    </div>
                )}
                <div className="hidden items-stretch lg:flex relative">
                    {NavigationData.map((item) => {
                        const active =
                            isActive(item.link, location) ||
                            hasActiveChild(item, location);

                        const hasDropdown =
                            item.children && item.children.length > 0;

                        const Inner = () => (
                            <ItemInner>
                                {item.label}
                                {hasDropdown && (
                                    <FontAwesomeIcon
                                        className="ml-2"
                                        icon={faAngleDown}
                                        size="1x"
                                    />
                                )}
                            </ItemInner>
                        );

                        return (
                            <NavbarItem
                                key={item.label}
                                active={active}
                                hasDropdown={hasDropdown}
                                isFullwidth={hasDropdown && item.full}
                            >
                                {item.link ? (
                                    <StyledLink to={item.link}>
                                        <Inner />
                                    </StyledLink>
                                ) : (
                                    <Inner />
                                )}

                                {hasDropdown && item.columns ? (
                                    <NavbarColumnsMenu>
                                        {chunk(item.children, 7).map(
                                            (col, i) => {
                                                if (!col) {
                                                    return null;
                                                }

                                                return (
                                                    <NavbarColumnsItem
                                                        key={`nav_col_${i}`}
                                                    >
                                                        {col.map((child) => {
                                                            const childActive = isActive(
                                                                child.link,
                                                                location
                                                            );

                                                            return (
                                                                <div
                                                                    key={
                                                                        col.label +
                                                                        '__' +
                                                                        child.label
                                                                    }
                                                                    style={{
                                                                        maxWidth:
                                                                            '300px',
                                                                    }}
                                                                    className={
                                                                        childActive
                                                                            ? 'bg-grey-lighter font-bold'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <NavbarDropdownItem
                                                                        to={
                                                                            child.link
                                                                        }
                                                                    >
                                                                        {
                                                                            child.label
                                                                        }
                                                                        <FontAwesomeIcon
                                                                            className="ml-4"
                                                                            icon={
                                                                                faAngleRight
                                                                            }
                                                                            size="1x"
                                                                        />
                                                                    </NavbarDropdownItem>
                                                                </div>
                                                            );
                                                        })}
                                                    </NavbarColumnsItem>
                                                );
                                            }
                                        )}
                                    </NavbarColumnsMenu>
                                ) : null}

                                {hasDropdown && item.full ? (
                                    <NavbarFullwidthMenu>
                                        <NavbarFullwidthLeft />
                                        <NavbarFullwidthContent>
                                            {item.children.map((child) => {
                                                const childActive = isActive(
                                                    child.link,
                                                    location
                                                );

                                                return (
                                                    <NavbarFullwidthSubmenu
                                                        key={`submenu_${child.label}`}
                                                    >
                                                        <NavbarFullwidthSubmenuHead
                                                            to={child.link}
                                                        >
                                                            {child.label}
                                                        </NavbarFullwidthSubmenuHead>

                                                        {child.children && (
                                                            <div>
                                                                {child.children.map(
                                                                    (
                                                                        childChild
                                                                    ) => (
                                                                        <NavbarFullwidthSubmenuLink
                                                                            key={`child_submenu_${childChild.label}`}
                                                                            to={
                                                                                childChild.link
                                                                            }
                                                                        >
                                                                            {
                                                                                childChild.label
                                                                            }
                                                                        </NavbarFullwidthSubmenuLink>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                    </NavbarFullwidthSubmenu>
                                                );
                                            })}
                                        </NavbarFullwidthContent>
                                        <NavbarFullwidthRight />
                                    </NavbarFullwidthMenu>
                                ) : null}

                                {hasDropdown && !item.full && !item.columns ? (
                                    <NavbarDropdown>
                                        {item.children.map((child) => {
                                            const childActive = isActive(
                                                child.link,
                                                location
                                            );

                                            return (
                                                <div
                                                    key={
                                                        item.label +
                                                        '__' +
                                                        child.label
                                                    }
                                                    className={
                                                        childActive
                                                            ? 'bg-grey-lighter font-bold'
                                                            : ''
                                                    }
                                                >
                                                    <NavbarDropdownItem
                                                        to={child.link}
                                                    >
                                                        {child.label}
                                                        <FontAwesomeIcon
                                                            className="ml-4"
                                                            icon={faAngleRight}
                                                            size="1x"
                                                        />
                                                    </NavbarDropdownItem>
                                                </div>
                                            );
                                        })}
                                    </NavbarDropdown>
                                ) : null}
                            </NavbarItem>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default NavBar;
