import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { isActive } from '../utils/navigation';

const MobileNavLink = ({ location, label, data }) => {
    const active = isActive(data.link, location);

    return (
        <Link
            key={`dropdown__${data.label}`}
            to={data.link}
            className="no-underline"
        >
            <div
                className={classNames(
                    'no-underline flex justify-between p-4 pl-10 border-b border-grey',
                    {
                        'text-lighten': !active,
                        'text-white': active,
                        'font-bold': active,
                    }
                )}
            >
                {label || data.label}

                <FontAwesomeIcon
                    className="ml-2"
                    icon={faAngleRight}
                    size="lg"
                />
            </div>
        </Link>
    );
};

export default MobileNavLink;
